<template>
  <div>
    <navtop></navtop>
    <menuNav />
    <router-view />
  </div>
</template>

<script>
import navtop from "@/components/navtop.vue";
import menuNav from "@/components/menuNav.vue";
export default {
  name: "synchronous",
  data() {
    return {};
  },
  components: { navtop, menuNav },
  mounted() {},
  methods: {},
};
</script>

<style lang='less'>
</style>
